import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
    BehaviorSubject,
    Observable,
    Observer,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { VehicleRequestSerializer } from 'src/app/models/serializers/vehicle-request-serializer';
import { Create } from '../http-methods/create';
import { AuthService } from '../auth.service';
import { VehicleRequest } from 'src/app/models/resource-models/vehicle-request';
import { Vehicle } from 'src/app/models/resource-models/vehicle';
import { VehicleSerializer } from 'src/app/models/serializers/vehicle-serializer';
import { List } from '../http-methods/list';
import { VehicleBrand } from 'src/app/models/resource-models/vehicle-brand';
import { VehicleBrandSerializer } from 'src/app/models/serializers/vehicle-brand-serializer';
import { VehicleModel } from 'src/app/models/resource-models/vehicle-model';
import { VehicleModelSerializer } from 'src/app/models/serializers/vehicle-model-serializer';
import { FuelType } from 'src/app/models/resource-models/fuel-type';
import { FuelTypeSerializer } from 'src/app/models/serializers/fuel-type-serializer';
import { VehicleBrandRequest } from 'src/app/models/resource-models/vehicle-brand-request';
import { VehicleBrandRequestSerializer } from 'src/app/models/serializers/vehicle-brand-request-serializer';
import { VehicleModelRequest } from 'src/app/models/resource-models/vehicle-model-request';
import { VehicleModelRequestSerializer } from 'src/app/models/serializers/vehicle-model-request-serializer';
import { ExpenseType } from 'src/app/models/resource-models/expense-type';
import { ExpenseTypeSerializer } from 'src/app/models/serializers/expense-type-serializer';
import { OdometerEntryRequest } from 'src/app/models/resource-models/odometer-entry-request';
import { OdometerEntryRequestSerializer } from 'src/app/models/serializers/odometer-entry-request-serializer';
import { VehicleExpense } from 'src/app/models/resource-models/vehicle-expense';
import { VehicleExpenseSerializer } from 'src/app/models/serializers/vehicle-expense-serializer';
import { EmptySerializer } from 'src/app/models/serializers/empty-serializer';
import { ChartExpenseData } from 'src/app/models/resource-models/chart-expense-data';
import { ChartExpenseDataSerializer } from 'src/app/models/serializers/chart-expense-data-serializer';
import { ChartOdometerData } from 'src/app/models/resource-models/chart-odometer-data';
import { ChartOdometerDataSerializer } from 'src/app/models/serializers/chart-odometer-data-serializer';
import { Read } from '../http-methods/read';
import { Patch } from '../http-methods/patch';
import { Empty } from 'src/app/models/resource-models/empty';
import { VehicleExpensePaginate } from 'src/app/models/resource-models/vehicle-expense-paginate';
import { VehicleExpensePaginateSerializer } from 'src/app/models/serializers/vehicle-expense-paginate-serializer';
import { Delete } from '../http-methods/delete';
import { VehicleDeleteRequest } from 'src/app/models/resource-models/vehicle-delete-request';
import { VehicleDeleteRequestSerializer } from 'src/app/models/serializers/vehicle-delete-request-serializer';
import { VehicleHistoryEntryPaginate } from 'src/app/models/resource-models/vehicle-history-entry-paginate';
import { VehicleHistoryEntryPaginateSerializer } from 'src/app/models/serializers/vehicle-history-entry-paginate-serializer';
import { StorageHelper } from 'src/app/helpers/storage-helper';
import { VehicleCodeRequest } from 'src/app/models/resource-models/vehicle-code-request';
import { VehicleCodeRequestSerializer } from 'src/app/models/serializers/vehicle-code-request-serializer';
import { VehicleDriver } from 'src/app/models/resource-models/vehicle-driver';
import { VehicleDriverSerializer } from 'src/app/models/serializers/vehicle-driver-serializer';
import { VehiclePermission } from 'src/app/models/resource-models/vehicle-permission';
import { VehiclePermissionSerializer } from 'src/app/models/serializers/vehicle-permission-serializer';
import { VehicleOdometerEntry } from 'src/app/models/resource-models/vehicle-odometer-entry';
import { VehicleOdometerEntrySerializer } from 'src/app/models/serializers/vehicle-odometer-entry-serializer';
import { VehicleType } from 'src/app/models/resource-models/vehicle-type';
import { VehicleTypeSerializer } from 'src/app/models/serializers/vehicle-type-serializer';
import { VehicleDriverInviteSerializer } from 'src/app/models/serializers/vehicle-driver-invite-serializer';
import { VehicleDriverInvite } from 'src/app/models/resource-models/vehicle-driver-invite';
import { VehicleInviteCode } from 'src/app/models/resource-models/vehicle-invite-code';
import { VehicleInviteCodeSerializer } from 'src/app/models/serializers/vehicle-invite-code-serializer';
import { VehicleDriverInviteRequest } from 'src/app/models/resource-models/vehicle-driver-invite-request';
import { VehicleDriverInviteRequestSerializer } from 'src/app/models/serializers/vehicle-driver-invite-request-serializer';
import { VehicleInviteSignUpRequest } from 'src/app/models/resource-models/vehicle-invite-sign-up-request';
import { VehicleInviteSignUpRequestSerializer } from 'src/app/models/serializers/vehicle-invite-sign-up-request-serializer';
import { BearerTokenSerializer } from 'src/app/models/serializers/bearer-token-serializer';
import { BearerToken } from 'src/app/models/resource-models/bearer-token';
import { VehicleTransferInvite } from 'src/app/models/resource-models/vehicle-transfer-invite';
import { VehicleTransferInviteSerializer } from 'src/app/models/serializers/vehicle-transfer-invite-serializer';
import { TechnicalVisit } from 'src/app/models/resource-models/technical-visit';
import { TechnicalVisitSerializer } from 'src/app/models/serializers/technical-visit-serializer';
import { VehicleTransferRequest } from 'src/app/models/resource-models/vehicle-transfer-request';
import { VehicleTransferRequestSerializer } from 'src/app/models/serializers/vehicle-transfer-request-serializer';
import { VehicleTransfersOverview } from 'src/app/models/resource-models/vehicle-transfers-overview';
import { VehicleTransfersOverviewSerializer } from 'src/app/models/serializers/vehicle-transfers-overview-serializer';
import { DsbLink } from 'src/app/models/resource-models/dsb-link';
import { DsbLinkSerializer } from 'src/app/models/serializers/dsb-link-serializer';
import { DsbLinkPreview } from 'src/app/models/resource-models/dsb-link-preview';
import { DsbLinkPreviewSerializer } from 'src/app/models/serializers/dsb-link-preview-serializer';

@Injectable({
    providedIn: 'root',
})
export class VehicleService {
    public endpoint = environment.vehicleVersion + '/car';
    public carFromCodeEndpoint =
        environment.vehicleVersion + '/cars/drivers-code';
    public brandEndpoint = environment.vehicleVersion + '/brand';
    public modelEndpoint = environment.vehicleVersion + '/model';
    public expenseTypeEndpoint = environment.vehicleVersion + '/expenseTypes';
    public odometerEntryEndpoint = environment.vehicleVersion + '/odometer';
    public expenseEndpoint = environment.vehicleVersion + '/expense';
    public expenseListEndpoint = environment.vehicleVersion + '/expenses';
    public chartDataEndpoint = environment.vehicleVersion + '/chart';
    public vehicleHistoryEndpoint = environment.vehicleVersion + '/car-history';

    private createMethod: Create<VehicleRequest, Vehicle>;
    private createFromCodeMethod: Create<VehicleCodeRequest, Empty>;
    private createDsbLinkMethod: Create<DsbLink, Empty>;
    private listDsbLinkMethod: List<DsbLink>;
    private deleteDsbLinkMethod: Delete<Empty>;
    private patchDsbLinkMethod: Patch<DsbLink, Empty>;
    private readDsbLinkPreview: Read<DsbLinkPreview>;
    private patchVehicleInvite: Patch<VehicleDriverInviteRequest, Empty>;
    private patchMethod: Patch<VehicleRequest, Empty>;
    private patchDriversCodeMethod: Patch<Empty, Vehicle>;
    private createFavoriteMethod: Create<Empty, Empty>;
    private patchDriversPermissions: Patch<VehiclePermission, Vehicle>;
    private patchVehiclePermissions: Patch<VehiclePermission, Vehicle>;
    private patchOdometerEntryMethod: Patch<
        VehicleOdometerEntry,
        VehicleOdometerEntry
    >;

    private listMethod: List<Vehicle>;
    private listInvitesMethod: List<VehicleDriverInvite>;
    private readVehicleCodeMethod: Read<VehicleInviteCode>;
    private readVehicleInvite: Read<VehicleDriverInvite>;
    private readVehicleTransferInvite: Read<VehicleTransferInvite>;
    private readVehicleTransfersOverview: Read<VehicleTransfersOverview>;
    private readVehicleTransfer: List<VehicleTransferInvite>;

    private listVehicleTechnicalVisits: List<TechnicalVisit>;
    private listVehicleOdometerMethod: List<VehicleOdometerEntry>;
    private listVehicleExpensesMethod: List<VehicleExpense>;
    private listVehicleExpensesOverviewMethod: List<ChartExpenseData>;
    private createSignUpFromTransfersMethod: Create<
        VehicleInviteSignUpRequest,
        BearerToken
    >;
    private createVehicleTransferMethod: Create<Empty, Empty>;
    private createVehicleTransferRequestMethod: Create<
        VehicleTransferRequest,
        Empty
    >;
    private createSignUpFromInvitesMethod: Create<
        VehicleInviteSignUpRequest,
        BearerToken
    >;
    private createVehicleInviteMethod: Create<
        VehicleDriverInviteRequest,
        Empty
    >;
    private listBrands: List<VehicleBrand>;
    private listModels: List<VehicleModel>;
    private listFuelTypes: List<FuelType>;
    private listVehicleTypes: List<VehicleType>;
    private listDrivers: List<VehicleDriver>;
    private createCarBrandMethod: Create<VehicleBrandRequest, VehicleBrand>;
    private createCarModelMethod: Create<VehicleModelRequest, VehicleModel>;
    private listExpenseTypes: List<ExpenseType>;
    private createOdometerEntryMethod: Create<OdometerEntryRequest, Vehicle>;
    private listOdometerEntriesMethod: List<VehicleOdometerEntry>;
    private readOdometerEntryMethod: Read<VehicleOdometerEntry>;

    private createExpenseMethod: Create<FormData, VehicleExpense>;
    private updateExpenseMethod: Create<FormData, VehicleExpense>;

    private listChartExpenseDataMethod: List<ChartExpenseData>;
    private readOdometerDataMethod: Read<ChartOdometerData>;

    private readMethod: Read<Vehicle>;
    private readExpensesPaginator: Read<VehicleExpensePaginate>;
    private readExpenseMethod: Read<VehicleExpense>;

    private deleteExpenseMethod: Delete<Empty>;
    private deleteOdometerMethod: Delete<Empty>;
    private deleteVehicleMethod: Create<VehicleDeleteRequest, Empty>;
    private deleteVehicleInviteMethod: Delete<Empty>;
    private deleteVehicleDriverMethod: Delete<Empty>;
    private deleteVehicleTransferInviteMethod: Delete<Empty>;

    private readHistoryPaginator: Read<VehicleHistoryEntryPaginate>;

    public vehicleTransferSubject = new BehaviorSubject<boolean>(false);
    public hasVehicleTransfer$: Observable<boolean> =
        this.vehicleTransferSubject.asObservable();

    public dsbLinkSubject = new BehaviorSubject<number | null>(null);
    public hasDsbLink$: Observable<number | null> =
        this.dsbLinkSubject.asObservable();

    public vehicleDriversSubject = new BehaviorSubject<number | null>(null);
    public hasVehicleDrivers$: Observable<number | null> =
        this.vehicleDriversSubject.asObservable();

    // cached data
    private dsbLinks: DsbLink[] | undefined;
    private dsbLinkPreview: DsbLinkPreview | undefined;

    constructor(protected httpClient: HttpClient, protected auth: AuthService) {
        this.createMethod = new Create<VehicleRequest, Vehicle>(
            httpClient,
            auth,
            new VehicleRequestSerializer(),
            new VehicleSerializer(),
            null,
            this.endpoint
        );
        this.createDsbLinkMethod = new Create<DsbLink, Empty>(
            httpClient,
            auth,
            new DsbLinkSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.listDsbLinkMethod = new List<DsbLink>(
            httpClient,
            auth,
            new DsbLinkSerializer(),
            null,
            this.endpoint
        );
        this.deleteDsbLinkMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.patchDsbLinkMethod = new Patch<DsbLink, Empty>(
            httpClient,
            auth,
            new DsbLinkSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.createFromCodeMethod = new Create<VehicleCodeRequest, Empty>(
            httpClient,
            auth,
            new VehicleCodeRequestSerializer(),
            new EmptySerializer(),
            null,
            this.carFromCodeEndpoint
        );
        this.patchMethod = new Patch<VehicleRequest, Empty>(
            httpClient,
            auth,
            new VehicleRequestSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.patchDriversCodeMethod = new Patch<Empty, Vehicle>(
            httpClient,
            auth,
            new EmptySerializer(),
            new VehicleSerializer(),
            'drivers-code',
            this.endpoint
        );
        this.patchDriversPermissions = new Patch<VehiclePermission, Vehicle>(
            httpClient,
            auth,
            new VehiclePermissionSerializer(),
            new VehicleSerializer(),
            'drivers',
            this.endpoint
        );
        this.patchVehiclePermissions = new Patch<VehiclePermission, Vehicle>(
            httpClient,
            auth,
            new VehiclePermissionSerializer(),
            new VehicleSerializer(),
            'permissions',
            this.endpoint
        );
        this.patchVehicleInvite = new Patch<VehicleDriverInviteRequest, Empty>(
            httpClient,
            auth,
            new VehicleDriverInviteRequestSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.patchOdometerEntryMethod = new Patch<
            VehicleOdometerEntry,
            VehicleOdometerEntry
        >(
            httpClient,
            auth,
            new VehicleOdometerEntrySerializer(),
            new VehicleOdometerEntrySerializer(),
            null,
            environment.vehicleVersion + '/odometer'
        );
        this.createFavoriteMethod = new Create<Empty, Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            new EmptySerializer(),
            null,
            this.endpoint + '/setFavorite'
        );
        this.listMethod = new List<Vehicle>(
            httpClient,
            auth,
            new VehicleSerializer(),
            'cars',
            environment.vehicleVersion
        );
        this.listInvitesMethod = new List<VehicleDriverInvite>(
            httpClient,
            auth,
            new VehicleDriverInviteSerializer(),
            'car',
            environment.vehicleVersion
        );
        this.readVehicleCodeMethod = new Read<VehicleInviteCode>(
            httpClient,
            auth,
            new VehicleInviteCodeSerializer(),
            'car',
            environment.vehicleVersion
        );
        this.readVehicleInvite = new Read<VehicleDriverInvite>(
            httpClient,
            auth,
            new VehicleDriverInviteSerializer(),
            'invites',
            environment.vehicleVersion
        );
        this.readVehicleTransferInvite = new Read<VehicleTransferInvite>(
            httpClient,
            auth,
            new VehicleTransferInviteSerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.readDsbLinkPreview = new Read<DsbLinkPreview>(
            httpClient,
            auth,
            new DsbLinkPreviewSerializer(),
            'dsb',
            environment.vehicleVersion
        );
        this.readVehicleTransfer = new List<VehicleTransferInvite>(
            httpClient,
            auth,
            new VehicleTransferInviteSerializer(),
            'car',
            environment.vehicleVersion
        );
        this.readVehicleTransfersOverview = new Read<VehicleTransfersOverview>(
            httpClient,
            auth,
            new VehicleTransfersOverviewSerializer(),
            'car',
            environment.vehicleVersion
        );
        this.listVehicleTechnicalVisits = new List<TechnicalVisit>(
            httpClient,
            auth,
            new TechnicalVisitSerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.listVehicleOdometerMethod = new List<VehicleOdometerEntry>(
            httpClient,
            auth,
            new VehicleOdometerEntrySerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.listVehicleExpensesMethod = new List<VehicleExpense>(
            httpClient,
            auth,
            new VehicleExpenseSerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.listVehicleExpensesOverviewMethod = new List<ChartExpenseData>(
            httpClient,
            auth,
            new ChartExpenseDataSerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.createSignUpFromTransfersMethod = new Create<
            VehicleInviteSignUpRequest,
            BearerToken
        >(
            httpClient,
            auth,
            new VehicleInviteSignUpRequestSerializer(),
            new BearerTokenSerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.createVehicleTransferMethod = new Create<Empty, Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            new EmptySerializer(),
            'transfers',
            environment.vehicleVersion
        );
        this.createVehicleTransferRequestMethod = new Create<
            VehicleTransferRequest,
            Empty
        >(
            httpClient,
            auth,
            new VehicleTransferRequestSerializer(),
            new EmptySerializer(),
            'car',
            environment.vehicleVersion
        );
        this.createSignUpFromInvitesMethod = new Create<
            VehicleInviteSignUpRequest,
            BearerToken
        >(
            httpClient,
            auth,
            new VehicleInviteSignUpRequestSerializer(),
            new BearerTokenSerializer(),
            'invites',
            environment.vehicleVersion
        );
        this.createVehicleInviteMethod = new Create<
            VehicleDriverInviteRequest,
            Empty
        >(
            httpClient,
            auth,
            new VehicleDriverInviteRequestSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.listBrands = new List<VehicleBrand>(
            httpClient,
            auth,
            new VehicleBrandSerializer(),
            'brands',
            environment.vehicleVersion
        );
        this.listModels = new List<VehicleModel>(
            httpClient,
            auth,
            new VehicleModelSerializer(),
            'models',
            environment.vehicleVersion
        );
        this.listFuelTypes = new List<FuelType>(
            httpClient,
            auth,
            new FuelTypeSerializer(),
            'fuelTypes',
            environment.vehicleVersion
        );
        this.listVehicleTypes = new List<VehicleType>(
            httpClient,
            auth,
            new VehicleTypeSerializer(),
            'vehicleTypes',
            environment.vehicleVersion
        );
        this.listDrivers = new List<VehicleDriver>(
            httpClient,
            auth,
            new VehicleDriverSerializer(),
            'drivers',
            this.endpoint
        );
        this.createCarBrandMethod = new Create<
            VehicleBrandRequest,
            VehicleBrand
        >(
            httpClient,
            auth,
            new VehicleBrandRequestSerializer(),
            new VehicleBrandSerializer(),
            null,
            this.brandEndpoint
        );
        this.createCarModelMethod = new Create<
            VehicleModelRequest,
            VehicleModel
        >(
            httpClient,
            auth,
            new VehicleModelRequestSerializer(),
            new VehicleModelSerializer(),
            null,
            this.modelEndpoint
        );
        this.listExpenseTypes = new List<ExpenseType>(
            httpClient,
            auth,
            new ExpenseTypeSerializer(),
            '',
            this.expenseTypeEndpoint
        );
        this.createOdometerEntryMethod = new Create<
            OdometerEntryRequest,
            Vehicle
        >(
            httpClient,
            auth,
            new OdometerEntryRequestSerializer(),
            new VehicleSerializer(),
            null,
            this.odometerEntryEndpoint
        );
        this.listOdometerEntriesMethod = new List<VehicleOdometerEntry>(
            httpClient,
            auth,
            new VehicleOdometerEntrySerializer(),
            '',
            this.odometerEntryEndpoint
        );
        this.readOdometerEntryMethod = new Read<VehicleOdometerEntry>(
            httpClient,
            auth,
            new VehicleOdometerEntrySerializer(),
            null,
            this.odometerEntryEndpoint
        );
        this.createExpenseMethod = new Create<FormData, VehicleExpense>(
            httpClient,
            auth,
            new EmptySerializer(),
            new VehicleExpenseSerializer(),
            null,
            this.expenseEndpoint
        );
        this.updateExpenseMethod = new Create<FormData, VehicleExpense>(
            httpClient,
            auth,
            new EmptySerializer(),
            new VehicleExpenseSerializer(),
            null,
            this.expenseEndpoint
        );
        this.listChartExpenseDataMethod = new List<ChartExpenseData>(
            httpClient,
            auth,
            new ChartExpenseDataSerializer(),
            'expenses',
            this.chartDataEndpoint
        );
        this.readOdometerDataMethod = new Read<ChartOdometerData>(
            httpClient,
            auth,
            new ChartOdometerDataSerializer(),
            'odometerHistory',
            this.chartDataEndpoint
        );
        this.readMethod = new Read<Vehicle>(
            httpClient,
            auth,
            new VehicleSerializer(),
            'car',
            environment.vehicleVersion
        );
        this.readExpensesPaginator = new Read<VehicleExpensePaginate>(
            httpClient,
            auth,
            new VehicleExpensePaginateSerializer(),
            '',
            this.expenseListEndpoint
        );
        this.deleteExpenseMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.expenseEndpoint
        );
        this.deleteOdometerMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.odometerEntryEndpoint
        );
        this.deleteVehicleMethod = new Create<VehicleDeleteRequest, Empty>(
            httpClient,
            auth,
            new VehicleDeleteRequestSerializer(),
            new EmptySerializer(),
            null,
            this.endpoint + '/delete'
        );
        this.deleteVehicleInviteMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.deleteVehicleDriverMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.deleteVehicleTransferInviteMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.endpoint
        );
        this.readHistoryPaginator = new Read<VehicleHistoryEntryPaginate>(
            httpClient,
            auth,
            new VehicleHistoryEntryPaginateSerializer(),
            null,
            this.vehicleHistoryEndpoint
        );
        this.readExpenseMethod = new Read<VehicleExpense>(
            httpClient,
            auth,
            new VehicleExpenseSerializer(),
            null,
            this.expenseListEndpoint
        );
    }

    public updateDsbLink(
        carId: number,
        id: number | null,
        data: DsbLink
    ): Observable<Empty> {
        return this.patchDsbLinkMethod
            .patch(null, carId + '/dsb-links/' + id, data)
            .pipe(
                tap(() => {
                    this.clearDsbLinks();
                    this.clearDsbLinkPreview();
                })
            );
    }

    public deleteLink(carId: number, id: number | null): Observable<Empty> {
        return this.deleteDsbLinkMethod
            .delete(null, carId + '/dsb-links/' + id, null)
            .pipe(
                tap(() => {
                    this.clearDsbLinks();
                    this.clearDsbLinkPreview();
                })
            );
    }

    public getLinks(carId: number | undefined): Observable<DsbLink[]> {
        // If dsbLinks is already populated, return it immediately
        if (this.dsbLinks) {
            return new Observable((observer: Observer<DsbLink[]>) => {
                observer.next(this.dsbLinks ?? []);
                observer.complete();
            });
        }

        // If dsbLinks is not populated, make a call to the backend
        return this.listDsbLinkMethod.list(null, carId + '/dsb-links').pipe(
            tap((response: DsbLink[]) => {
                // Store the response in this.dsbLinks
                this.dsbLinks = response;
            }),
            // Create a new observable to emit the response
            switchMap((response: DsbLink[]) => {
                return new Observable((observer: Observer<DsbLink[]>) => {
                    observer.next(response);
                    observer.complete();
                });
            })
        );
    }

    public clearDsbLinks(): void {
        this.dsbLinks = undefined;
    }

    public createLink(carId: number, model: DsbLink): Observable<Empty> {
        return this.createDsbLinkMethod
            .create(null, carId + '/dsb-links', model)
            .pipe(
                tap(() => {
                    this.clearDsbLinks();
                    this.clearDsbLinkPreview();
                })
            );
    }

    public getDriverInvites(carId: number): Observable<VehicleDriverInvite[]> {
        return this.listInvitesMethod.list(null, carId + '/invites');
    }

    public getInviteCode(carId: number): Observable<VehicleInviteCode> {
        return this.readVehicleCodeMethod.read(
            null,
            carId + '/invites/code',
            {},
            null
        );
    }

    public getVehicleInvite(hash: string): Observable<VehicleDriverInvite> {
        return this.readVehicleInvite.read(null, hash, {}, null);
    }

    public getVehicleTransferInvite(
        hash: string | null
    ): Observable<VehicleTransferInvite> {
        return this.readVehicleTransferInvite.read(null, hash, {}, null);
    }
    public getDsbLinkPreview(hash: string | null): Observable<DsbLinkPreview> {
        // if dsbLinkPreview is already populated, return it immediately
        if (this.dsbLinkPreview) {
            return new Observable((observer: Observer<DsbLinkPreview>) => {
                if (this.dsbLinkPreview) {
                    observer.next(this.dsbLinkPreview);
                }
                observer.complete();
            });
        }
        // if dsbLinkPreview is not populated, make a call to the backend
        this.readDsbLinkPreview.listKey = null;
        return this.readDsbLinkPreview.read(null, hash, {}, null).pipe(
            tap((response: DsbLinkPreview) => {
                // Store the response in this.dsbLinkPreview
                this.dsbLinkPreview = response;
            }),
            // Create a new observable to emit the response
            switchMap((response: DsbLinkPreview) => {
                return new Observable((observer: Observer<DsbLinkPreview>) => {
                    observer.next(response);
                    observer.complete();
                });
            })
        )
    }

    public clearDsbLinkPreview(): void {
        this.dsbLinkPreview = undefined;
    }

    public getVehicleTransfer(
        carId: number
    ): Observable<VehicleTransferInvite[]> {
        return this.readVehicleTransfer.list(null, carId + '/transfers', {});
    }
    public getVehicleTransfersOverview(
        carId: number
    ): Observable<VehicleTransfersOverview> {
        return this.readVehicleTransfersOverview.read(
            null,
            carId + '/transfers/overview',
            {},
            null
        );
    }
    public getVehicleTechnicalVisits(
        hash: string | null
    ): Observable<TechnicalVisit[]> {
        return this.listVehicleTechnicalVisits.list(
            null,
            hash + '/technical-visits'
        );
    }
    public getVehicleOdomter(
        hash: string | null
    ): Observable<VehicleOdometerEntry[]> {
        return this.listVehicleOdometerMethod.list(null, hash + '/odometer');
    }
    public getVehicleExpenses(
        hash: string,
        expenseTypeId: number | null
    ): Observable<VehicleExpense[]> {
        let params: any = {};
        if (expenseTypeId) {
            params.expenseTypeId = expenseTypeId;
        }
        return this.listVehicleExpensesMethod.list(
            null,
            hash + '/expenses',
            params
        );
    }
    public getVehicleExpensesOverview(
        hash: string
    ): Observable<ChartExpenseData[]> {
        return this.listVehicleExpensesOverviewMethod.list(
            null,
            hash + '/expenses-overview'
        );
    }
    public createSignUpFromTransfers(
        hash: string,
        model: VehicleInviteSignUpRequest
    ): Observable<BearerToken> {
        this.createSignUpFromTransfersMethod.listKey = null;
        return this.createSignUpFromTransfersMethod.create(
            null,
            hash + '/signup',
            model
        );
    }
    public createVehicleTransfer(hash: string): Observable<Empty> {
        this.createVehicleTransferMethod.listKey = null;
        return this.createVehicleTransferMethod.create(
            null,
            hash + '/accept',
            {}
        );
    }
    public createVehicleTransferRequest(
        carId: number,
        model: VehicleTransferRequest
    ): Observable<Empty> {
        this.createVehicleTransferRequestMethod.listKey = null;
        return this.createVehicleTransferRequestMethod.create(
            null,
            carId + '/transfers',
            model
        );
    }
    public createSignUpFromInvites(
        hash: string,
        model: VehicleInviteSignUpRequest
    ): Observable<BearerToken> {
        this.createSignUpFromInvitesMethod.listKey = null;
        return this.createSignUpFromInvitesMethod.create(
            null,
            hash + '/signup',
            model
        );
    }

    public createVehicleInvite(
        carId: number,
        model: VehicleDriverInviteRequest
    ): Observable<Empty> {
        return this.createVehicleInviteMethod.create(
            null,
            carId + '/invites',
            model
        );
    }

    public updateVehicleInvite(
        carId: number,
        id: number,
        model: VehicleDriverInviteRequest
    ): Observable<Empty> {
        return this.patchVehicleInvite.patch(
            null,
            carId + '/invites/' + id,
            model
        );
    }

    public createVehicle(model: VehicleRequest): Observable<Vehicle> {
        this.removeVehiclesFromSession();
        return this.createMethod.create(null, null, model);
    }

    public createVehicleFromCode(model: VehicleCodeRequest): Observable<Empty> {
        return this.createFromCodeMethod.create(null, null, model);
    }

    public updateVehicle(model: VehicleRequest, id: number): Observable<Empty> {
        this.removeVehiclesFromSession();
        return this.patchMethod.patch(null, id, model);
    }

    public updateVehicleCode(vehicleId: number): Observable<Vehicle> {
        return this.patchDriversCodeMethod.patch(vehicleId, null, new Empty());
    }

    public updateDriversPermissions(
        vehicleId: number,
        userId: number | null | undefined,
        data: VehiclePermission
    ): Observable<Vehicle> {
        return this.patchDriversPermissions.patch(vehicleId, userId, data);
    }

    public updateVehiclePermissions(
        vehicleId: number,
        data: VehiclePermission
    ): Observable<Vehicle> {
        return this.patchVehiclePermissions.patch(vehicleId, null, data);
    }

    public updateOdometerNotes(
        odometerEntryId: number,
        data: VehicleOdometerEntry
    ): Observable<VehicleOdometerEntry> {
        return this.patchOdometerEntryMethod.patch(odometerEntryId, null, data);
    }

    public setAsFavorite(id: number): Observable<Empty> {
        this.removeVehiclesFromSession();
        return this.createFavoriteMethod.create(null, id, new Empty());
    }

    public createExpense(data: FormData): Observable<VehicleExpense> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.createExpenseMethod.createWithOptions(
            null,
            null,
            data,
            headers
        );
    }

    public updateExpense(
        data: FormData,
        id: number
    ): Observable<VehicleExpense> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.updateExpenseMethod.createWithOptions(
            null,
            id,
            data,
            headers
        );
    }

    public createVehicleBrand(
        model: VehicleBrandRequest
    ): Observable<VehicleBrand> {
        return this.createCarBrandMethod.create(null, null, model);
    }

    public createVehicleModel(
        model: VehicleModelRequest
    ): Observable<VehicleModel> {
        return this.createCarModelMethod.create(null, null, model);
    }

    public getVehicles(readFromCache = true): Observable<Vehicle[]> {
        const data = StorageHelper.getItem('vehicles');
        if (data !== null && readFromCache) {
            return new Observable((observer: Observer<Vehicle[]>) => {
                const item = JSON.parse(data);
                observer.next(item);
                observer.complete();
            });
        }
        return this.listMethod.list();
    }

    public getExpenses(params: {} = {}): Observable<VehicleExpensePaginate> {
        this.readExpensesPaginator.listKey = null;
        return this.readExpensesPaginator.read(null, null, params, null);
    }

    public getSingleExpense(id: number): Observable<VehicleExpense> {
        return this.readExpenseMethod.read(null, id, null, null);
    }

    public getSingleVehicle(
        id: number,
        readFromCache = true
    ): Observable<Vehicle> {
        const data = StorageHelper.getItem('vehicle_' + id);
        if (data !== null && readFromCache) {
            return new Observable((observer: Observer<Vehicle>) => {
                const item = JSON.parse(data);
                observer.next(item);
                observer.complete();
            });
        }
        return this.readMethod.read(null, id, null, null);
    }

    public saveVehiclesToSession(vehicles: Vehicle[]): void {
        StorageHelper.setItem('vehicles', JSON.stringify(vehicles), 10);
        vehicles.forEach((vehicle) => {
            StorageHelper.setItem(
                'vehicle_' + vehicle.id,
                JSON.stringify(vehicle),
                10
            );
        });
    }

    public removeVehiclesFromSession() {
        const storageVehicles = StorageHelper.getItem('vehicles');
        if (!storageVehicles) {
            return;
        }
        const vehicles = JSON.parse(storageVehicles) as Vehicle[];
        if (!vehicles || !vehicles.length) {
            return;
        }
        vehicles.forEach((vehicle) => {
            StorageHelper.removeItem('vehicle_' + vehicle.id);
        });
        StorageHelper.removeItem('vehicles');
    }

    public removeSingleVehicleFromSession(vehicleId: number) {
        StorageHelper.removeItem('vehicle_' + vehicleId);
        const storageVehicles = StorageHelper.getItem('vehicles');
        if (!storageVehicles) {
            return;
        }
        const vehicles = JSON.parse(storageVehicles) as Vehicle[];
        if (!vehicles || !vehicles.length) {
            return;
        }
        const indexToRemove = vehicles.findIndex(
            (vehicle) => vehicle.id === vehicleId
        );
        if (indexToRemove !== -1) {
            if (vehicles.length === 1) {
                StorageHelper.removeItem('vehicles');
            } else {
                vehicles.splice(indexToRemove, 1);
                StorageHelper.setItem('vehicles', JSON.stringify(vehicles), 10);
            }
        }
    }

    public getBrands(): Observable<VehicleBrand[]> {
        return this.listBrands.list();
    }

    public getModels(brandId: number): Observable<VehicleModel[]> {
        return this.listModels.list(null, brandId);
    }

    public getFuelTypes(): Observable<FuelType[]> {
        return this.listFuelTypes.list();
    }

    public getVehicleTypes(): Observable<VehicleType[]> {
        return this.listVehicleTypes.list();
    }

    public getVehicleDrivers(vehicleId: number): Observable<VehicleDriver[]> {
        return this.listDrivers.list(vehicleId);
    }

    public getExpenseTypes(): Observable<ExpenseType[]> {
        return this.listExpenseTypes.list();
    }

    public setOdometer(model: OdometerEntryRequest): Observable<Vehicle> {
        return this.createOdometerEntryMethod.create(null, null, model);
    }

    public getOdometerEntries(
        vehicleId: number
    ): Observable<VehicleOdometerEntry[]> {
        return this.listOdometerEntriesMethod.list(null, null, {
            car_id: vehicleId,
        });
    }

    public getSingleOdometerEntry(
        odometerEntryId: number
    ): Observable<VehicleOdometerEntry> {
        return this.readOdometerEntryMethod.read(
            null,
            odometerEntryId,
            {},
            null
        );
    }

    public getMaxOdometerValue(current: number): number {
        return 1000000;
    }

    public getChartExpenseData(
        carIds: number[] | null = null,
        fromDate: string | null = null,
        toDate: string | null = null,
        withTechnicalVisits: boolean = false
    ): Observable<ChartExpenseData[]> {
        let params: any = {};
        if (carIds) {
            params.car_ids = carIds.join(',');
        }
        if (fromDate) {
            params.from_date = fromDate;
        }
        if (toDate) {
            params.to_date = toDate;
        }
        if (withTechnicalVisits) {
            params.with_technical_visits = true;
        }
        return this.listChartExpenseDataMethod.list(null, null, params);
    }

    public getOdometerHistoryData(
        carId: number
    ): Observable<ChartOdometerData> {
        return this.readOdometerDataMethod.read(null, carId, {}, null);
    }

    public deleteVehicleExpense(id: number): Observable<Empty> {
        return this.deleteExpenseMethod.delete(null, id, null);
    }

    public deleteOdometerEntry(odometerEntryId: number): Observable<Empty> {
        return this.deleteOdometerMethod.delete(null, odometerEntryId, null);
    }

    public deleteVehicle(
        vehicleId: number,
        model: VehicleDeleteRequest
    ): Observable<Empty> {
        return this.deleteVehicleMethod.create(null, vehicleId, model);
    }

    public deleteVehicleInvite(carId: number, id: number): Observable<Empty> {
        return this.deleteVehicleInviteMethod.delete(
            null,
            carId + '/invites/' + id,
            null
        );
    }

    public deleteVehicleDriver(carId: number, id: number): Observable<Empty> {
        return this.deleteVehicleDriverMethod.delete(
            null,
            carId + '/drivers/' + id,
            null
        );
    }

    public deleteVehicleTransfer(carId: number, id: number): Observable<Empty> {
        return this.deleteVehicleTransferInviteMethod.delete(
            null,
            carId + '/transfers/' + id,
            null
        );
    }

    public getVehicleHistory(
        vehicleId: number,
        params: {} = {}
    ): Observable<VehicleHistoryEntryPaginate> {
        this.readHistoryPaginator.listKey = null;
        return this.readHistoryPaginator.read(null, vehicleId, params, null);
    }

    public setHasVehicleTransfer(val: boolean) {
        this.vehicleTransferSubject.next(val);
    }

    public setHasVehicleDrivers(val: number | null) {
        this.vehicleDriversSubject.next(val);
    }

    public setHasDsbLinks(val: number | null) {
        this.dsbLinkSubject.next(val);
    }
}
