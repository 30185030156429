import {Serializer} from './serializer';
import {ArrayService} from '../../services/array.service';
import { File } from '../resource-models/file';
import { environment } from 'src/environments/environment';

export class FileSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): File {
        return new File(
            this.arrayService.getValue(json, 'id'),
            this.getPath(json),
            this.arrayService.getValue(json, 'bytes'),
            this.arrayService.getValue(json, 'width'),
            this.arrayService.getValue(json, 'height'),
            this.arrayService.getValue(json, 'file_type'),
            this.arrayService.getValue(json, 'created_at'),
            this.checkIsVideo(json)
        );
    }

    toJson(resource: File): any {
        return {}
    }

    private getPath(json: any) {
        const isVideo = this.checkIsVideo(json);
        let path = this.arrayService.getValue(json, 'path');
        if (isVideo || path.includes('http')) {
            return path;
        }
        return environment.apiUrl + '/' + environment.imageVersion + path;
    }

    private checkIsVideo(json: any) {
        let fileType = this.arrayService.getValue(json, 'file_type');
        // check if contains video string
        return fileType && fileType.includes('video') ? true : false;
    }

}
