import { Resource } from './resource';

export class DsbLink extends Resource {
    override id : number | null;
    uuid: string;
    name: string;
    is_active: boolean;
    access_files: boolean;
    access_videos: boolean;
    created_at: string;
    updated_at: string;
    url: string;

    constructor(
        id: number | null,
        uuid: string,
        name: string,
        is_active: boolean,
        access_files: boolean,
        access_videos: boolean,
        created_at: string,
        updated_at: string,
        url: string
    ) {
        super();
        this.id = id;
        this.uuid = uuid;
        this.name = name;
        this.is_active = is_active;
        this.access_files = access_files;
        this.access_videos = access_videos;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.url = url;
    }
}
