import { Serializer } from './serializer';
import { VehicleToImport } from '../resource-models/vehicle-to-import';

export class VehicleToImportSerializer implements Serializer {

    fromJson(json: any): VehicleToImport {
        return new VehicleToImport(
            json.car_model_id,
            json.name,
            json.year,
            json.power,
            json.vin,
            json.fuel,
            json.type,
            json.plates,
            json.last_registered,
        );
    }

    toJson(resource: VehicleToImport): any {
        return {};
    }

}
