import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { DsbLink } from '../resource-models/dsb-link';

export class DsbLinkSerializer implements Serializer {
    private arrayService = new ArrayService();

    fromJson(json: any): DsbLink {
        return new DsbLink(
            this.arrayService.getValue(json, 'id'),
            this.arrayService.getValue(json, 'uuid'),
            this.arrayService.getValue(json, 'name'),
            this.arrayService.getValue(json, 'is_active'),
            this.arrayService.getValue(json, 'access_files'),
            this.arrayService.getValue(json, 'access_videos'),
            this.arrayService.getValue(json, 'created_at'),
            this.arrayService.getValue(json, 'updated_at'),
            this.arrayService.getValue(json, 'url'),
        );
    }

    toJson(resource: DsbLink): any {
        return {
            name: resource.name,
            is_active: resource.is_active,
            access_files: resource.access_files,
            access_videos: resource.access_videos,
        };
    }
}
