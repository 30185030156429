import { Resource } from "./resource";
import { TechnicalVisit } from "./technical-visit";
import { Vehicle } from "./vehicle";

export class DsbLinkPreview extends Resource {
    override id: number;
    vehicle: Vehicle | null;
    technicalVisit: TechnicalVisit[];

    constructor(
        id: number,
        vehicle: Vehicle | null,
        technicalVisit: TechnicalVisit[],
    ) {
        super();
        this.id = id;
        this.vehicle = vehicle;
        this.technicalVisit = technicalVisit;
    }

}