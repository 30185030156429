<div class="site-layout" id="content-wrap" [class.has-bckg-image]="hasBckgImage">
    <nav class="fe-relative">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div style="line-height: 80px;">
                        <ng-container *ngIf="hasBackBtn$ | async as hasBackBtn">
                            <ng-container *ngIf="hasBackBtn">
                                <button class="btn btn-md btn-primary btn-outline text-black back-btn-holder fe-transform-y" (click)="goBack()">
                                    <img src="/assets/img/icons/CaretLeft.svg" alt="Back">
                                </button>
                            </ng-container>
                        </ng-container>
                        <ul class="pull-left fe-full-width">
                            <li class="fe-no-padding">
                                <a class="nav-link" [routerLink]="'/'">
                                    <img src="/assets/img/logo-full-black.svg" alt="Logo" width="250" class="icon">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <!-- Container -->
                <div class="mb-5">
                    <router-outlet></router-outlet>
                </div>
                <!-- Container -->
            </div>
        </div>
    </div>
</div>