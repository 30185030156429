import { Serializer } from './serializer';
import { ArrayService } from '../../services/array.service';
import { Vehicle } from '../resource-models/vehicle';
import { VehicleSerializer } from './vehicle-serializer';
import { DsbLinkPreview } from '../resource-models/dsb-link-preview';
import { TechnicalVisitSerializer } from './technical-visit-serializer';
import { TechnicalVisit } from '../resource-models/technical-visit';

export class DsbLinkPreviewSerializer implements Serializer {
    private arrayService = new ArrayService();
    private vehicleSerializer = new VehicleSerializer();
    private technicalVisitSerializer = new TechnicalVisitSerializer();

    fromJson(json: any): DsbLinkPreview {
        return new DsbLinkPreview(
            this.arrayService.getValue(json, 'id'),
            this.getVehicle(json),
            this.getTechnicalVisits(json),
        );
    }
    

    toJson(resource: DsbLinkPreview): any {
        return {}
    }

    private getVehicle(json: any): Vehicle | null {
        const dataItemJson = this.arrayService.getValue(json, 'vehicle');
        return dataItemJson ? this.vehicleSerializer.fromJson(dataItemJson) : null;
    }

    private getTechnicalVisits(json: any): TechnicalVisit[] | [] {
        const dataItemsArray = this.arrayService.getValue(json, 'technicalVisits');
        return dataItemsArray === null ? [] : dataItemsArray.map((dataItemJson: any) => {
            return this.technicalVisitSerializer.fromJson(dataItemJson);
        });
    }
    

}
