import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UiHelper } from './helpers/ui-helper';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './services/user/user.service';
import {
    NgcCookieConsentService,
    NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { Subscription, filter } from 'rxjs';
import { StorageHelper } from './helpers/storage-helper';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from './services/cookie.service';
import { AuthService } from './services/auth.service';
import { EchoSubscriptionService } from './services/echo-subscription.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('echoModal') echoModal!: any;
    title = 'AutoOptimo';
    private statusChangeSubscription!: Subscription;
    private hasCookieConsent = false;
    private routerSubscription!: Subscription;

    appStoreLink = environment.appStoreLink;
    playStoreLink = environment.playStoreLink;

    isHidden = false;
    shouldShowMobileWarning = false;
    isRouteMatchResolved = false;
    routesWithoutMobileWarning = [
        '/auth',
        '/vin-decoder',
        '/dsb-link'
    ];

    constructor(
        private readonly googleAnalyticsService: GoogleAnalyticsService,
        private route: ActivatedRoute,
        private userService: UserService,
        private ccService: NgcCookieConsentService,
        private translateService: TranslateService,
        private cookieService: CookieService,
        private auth: AuthService,
        private router: Router,
        private echoSubscriptionService: EchoSubscriptionService
    ) {
        if (!this.auth.isLoggedIn()) {
            this.cookieService.deleteLaravelSessionCookies();
        }
        this.routerSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                if (event instanceof NavigationEnd) {
                    this.shouldShowMobileWarning = !this.routesWithoutMobileWarning.some(
                        (route) => event.url.startsWith(route)
                    );
                    this.isRouteMatchResolved = true;
                }
            });
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const locale: string | null = params['locale'];
            if (locale) {
                this.userService.setLocale(locale);
            }
        });
        this.echoSubscriptionService.initialize();
        this.translateService
            .get([
                'cookie.header',
                'cookie.message',
                'cookie.dismiss',
                'cookie.allow',
                'cookie.deny',
                'cookie.link',
                'cookie.policy',
            ])
            .subscribe((data) => {
                const config = (this.ccService.getConfig().content =
                    this.ccService.getConfig().content || {});
                if (config) {
                    // Override default messages with the translated ones
                    config.header = data['cookie.header'];
                    config.message = data['cookie.message'];
                    config.dismiss = data['cookie.dismiss'];
                    config.allow = data['cookie.allow'];
                    config.deny = data['cookie.deny'];
                    config.link = data['cookie.link'];
                    config.policy = data['cookie.policy'];
                }

                this.ccService.destroy(); //remove previous cookie bar (with default messages)
                this.ccService.init(this.ccService.getConfig()); // update config with translated messages
            });
        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                if (event.status === 'allow') {
                    StorageHelper.setConfigItem('has_cookie_consent', 'true');
                    if (!event.chosenBefore) {
                        this.initGa();
                    }
                } else {
                    StorageHelper.removeConfigItem('has_cookie_consent');
                }
            }
        );
        const storageCookieConsent =
            StorageHelper.getConfigItem('has_cookie_consent');
        this.hasCookieConsent =
            storageCookieConsent && storageCookieConsent === 'true'
                ? true
                : false;
        if (this.hasCookieConsent) {
            this.initGa();
        }
    }

    private initGa() {
        if (environment.production) {
            this.googleAnalyticsService.initialize();
        }
    }

    ngAfterViewInit(): void {
        this.echoSubscriptionService.setEchoModal(this.echoModal);
    }

    ngOnDestroy() {
        this.statusChangeSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    onActivate(event: any) {
        UiHelper.scrollToTop();
    }

    dismiss(): void {
        this.isHidden = true;
    }
}
