import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AotDatePipe } from './aot-date.pipe';
import { TrimPipe } from './trim.pipe';
import { AotTimeLeftPipe } from './aot-time-left.pipe';
import { AotCurrencyPipe } from './aot-currency.pipe';
import { AotMetricPipe } from './aot-metric.pipe';
import { SecurePipe } from './secure.pipe';
import { AotFuelUnitPipe } from './aot-fuel-unit.pipe';
import { MapToArrayPipe } from './map-to-array.pipe';

@NgModule({
    declarations: [
        AotDatePipe,
        TrimPipe,
        AotTimeLeftPipe,
        AotCurrencyPipe,
        AotMetricPipe,
        SecurePipe,
        AotFuelUnitPipe,
        MapToArrayPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AotDatePipe,
        TrimPipe,
        AotTimeLeftPipe,
        AotCurrencyPipe,
        AotMetricPipe,
        SecurePipe,
        AotFuelUnitPipe,
        MapToArrayPipe
    ]
})
export class PipeModule { }
