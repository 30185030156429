import { Resource } from './resource';

export class VehicleToImport extends Resource {
    car_model_id: number;
    name: string;
    year: string;
    power: string;
    vin: string;
    fuel: string;
    type: string;
    plates: string;
    last_registered: string;

    constructor(
        car_model_id: number,
        name: string,
        year: string,
        power: string,
        vin: string,
        fuel: string,
        type: string,
        plates: string,
        last_registered: string
    ) {
        super();
        this.car_model_id = car_model_id;
        this.name = name;
        this.year = year;
        this.power = power;
        this.vin = vin;
        this.fuel = fuel;
        this.type = type;
        this.plates = plates;
        this.last_registered = last_registered;
    }
}
